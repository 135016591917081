import React from "react";
import Banner from "../../components/banner/banner";
import { header, termsConditions, ctaLinks } from "../../utils/how-it-works/termsInformations";
import Layout from "../../layouts/index.js";
import ListItem from "../../components/list-item/list-item";
import "../../styles/pages/terms-conditions.scss";
import CTAContainer from "../../components/cta-container/cta-container";

const TermsAndConditions = () => {

    return (

        <Layout SEOTitle={"Terms & Conditions | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <Banner props={header}></Banner>
            <div className="container-width">
                <div className="container-content">
                    <ul>
                        {
                            termsConditions.length ? termsConditions.map((item, index) => {

                                return (
                                    <ListItem text={item.text} key={index}></ListItem>
                                )
                            }) : ""
                        }
                    </ul>
                </div>
            </div>
            <CTAContainer links={ctaLinks}></CTAContainer>
        </Layout>
    )
}

export default TermsAndConditions;