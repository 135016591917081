import React from "react"
import "./banner.scss"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../button/button"

const Banner = ({ props }) => {

    return (
        <>
            <div className="banner-container">
                {
                    props.small == false ?
                        <div className="banner-container-default" style={{ background: props.bgColor ? props.bgColor : "#666666" }}>
                            <div className={props.padding == true ? "default-banner banner-padding" : "default-banner"} style={{ background: props.bgColor ? props.bgColor : "#666666" }}>
                                <div className="banner-content uk-align-center padding-top padding-bottom" data-uk-grid>
                                    <div className={props.inlineBtn ? "uk-align-center banner-elements-inline" : "uk-align-center banner-elements"}>
                                        {props.image ? <img src={props.image} alt={""} className="banner-img"></img> : ""}
                                        {props.title ?
                                            props.h1 ? <h1>{props.title}</h1> : <h2>{props.title}</h2>
                                            : ""
                                        }
                                        {props.subtitle ? <p>{props.subtitle}</p> : ""}
                                        {
                                            props.displayBtn ?
                                                <Button
                                                    link={props.link}
                                                    label={props.label}
                                                    url={props.url} />
                                                : ""
                                        }
                                        {
                                            props.inlineBtn ?
                                                props.inlineBtn.map((item, index) => {
                                                    return (
                                                        <Button
                                                            inline="true"
                                                            link={item.link}
                                                            label={item.label}
                                                            url={item.url} />
                                                    )
                                                }) : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="padding">
                            <div className="small-banner">
                                <div className="banner-content uk-align-center padding-top padding-bottom" data-uk-grid>
                                    <div className="uk-align-center banner-elements">
                                        {props.title ? <h2>{props.title}</h2> : ""}
                                        {
                                            props.displayBtn ?
                                                <Button
                                                    link={props.link}
                                                    label={props.label}
                                                    url={props.url} />
                                                : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                }
            </div>

        </>
    )

}

export default Banner