export const header = {
    small: false,
    title: "Terms & Conditions",
    h1: true,
    subtitle: "Important information you should read. But we've tried to keep it reader friendly.",
    bgColor: '#a8cf37',
    displayBtn: false,
    padding: true,
}

export const termsConditions = [
    {
        text: "Freemotorlegal.co.uk is a subscription accident management and uninsured loss recovery service"
    },
    {
        text: "Registering confirms your agreement to these terms and conditions"
    },
    {
        text: "Upon registering, you become eligible to the services Free Motor Legal Ltd are able to offer        "
    },
    {
        text: "You agree to use our appointed service providers (hire company, solicitor etc), but you are free to choose your own repairer"
    },
    {
        text: "The Free Motor Legal appointed solicitor will not deduct a success fee from your compensation"
    },
    {
        text: "The replacement vehicle will only be provided in the event you meet the terms and conditions of the hire company and agree to their terms and conditions        "
    },
    {
        text: "If you are at fault for the accident or the offending driver cannot be traced or identified, you will not be entitled to a replacement vehicle        "
    },
    {
        text: "If your accident claim is for damage only and does not include personal injury, you must notify the claim to us within 28 days of the incident, otherwise we may refuse to assist with your claim        "
    },
    {
        text: "Free Motor Legal is not an insurance policy, it is a membership service. As such Free Motor Legal endeavours to provide all services described, subject to reasonable prospects of success and the Terms and Conditions of our service providers        "
    },
    {
        text: "If the party at fault for the accident is untraced, you must make a formal report to the police within 5 days of the accident        "
    },
    {
        text: "By registering, you agree to receive periodical emails from FreeMotorLegal about our services and offers"
    },
]

export const ctaLinks = [
    {
        title: "Read our full Terms & Conditions here",
        url: "pdf",
        bgColor:"#0bbcbf"
    },
    {
        title: "Read our complaints procedure here",
        url: "/complaints-procedure",
        bgColor:"#0bbcbf"
    },
    {
        title: "Review our Privacy Policy here",
        url: "/privacy-policy",
        bgColor:"#0bbcbf"
    }
];