import React from "react";
import "./cta-link.scss";
import brochure from "../../assets/fml_brochure.pdf"


function CTALink({title, url, bgColor}){


    return(

        <div className="cta-link" style={bgColor ? {background: bgColor} : {}}>
            <div className="cta-link-content">
                <a href={url == "pdf" ? brochure : url} target="_blank">{title}</a>
            </div>
        </div>
    )

}

export default CTALink;