import React from "react";
import CTALink from "../cta-link/cta-link";
import "./cta-container.scss";

function CTAContainer({ links }) {


    return (
        <div className="cta-container-width">
            <div className={`uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-2@s  uk-grid-match uk-grid-small uk-grid container-flex`} uk-grid="true">
                {
                    links.length ? links.map((item, index) => {

                        return (
                            <CTALink
                                key={index}
                                title={item.title}
                                url={item.url}
                                bgColor={item.bgColor}
                            />
                        )
                    }) : ""
                }

            </div>
        </div>



    )

}

export default CTAContainer;